<template>
  <section class="section">
    <div class="container">
      <div
        v-if="expired"
        class="text-center"
      >
        <h3>This password reset link is expired</h3>
      </div>
      <div
        v-if="verificationPerformed && !expired"
        class="login-panel"
      >
        <div class="heading text-center">
          <h1>Change Password</h1>
        </div>
        <form
          class="form-horizontal mt-1"
          @submit.prevent="changePassword"
        >
          <div
            class="form-group"
            :class="{ 'form-error': errors.password }"
          >
            <label class="sr-only">Password</label>
            <input
              v-model="password"
              type="password"
              maxlength="40"
              class="form-control"
              placeholder="Password"
            >
          </div>
          <div
            class="form-group"
            :class="{ 'form-error': errors.password_confirmation }"
          >
            <label class="sr-only">Confirm Password</label>
            <input
              v-model="password_confirmation"
              type="password"
              maxlength="40"
              class="form-control"
              placeholder="Confirm Password"
            >
            <div
              v-for="(error, index) in errors.password"
              :key="index"
              class="form-error-message"
            >
              {{ error }}
            </div>
            <div
              v-for="(error, index) in errors.password_confirmation"
              :key="index"
              class="form-error-message"
            >
              {{ error }}
            </div>
          </div>
          <div class="form-group">
            <div
              v-if="showPasswordStrengthError"
              class="form-error-message"
            >
              Please choose stronger password.
            </div>
            <password-strength
              :password="password"
              @update-password-strength="updatePasswordStrength"
            />
          </div>
          <div class="form-group">
            <button
              type="submit"
              class="btn btn-lg btn-block btn-primary"
            >
              Change Password
            </button>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import PasswordStrength from '@/components/PasswordStrength.vue';
import PasswordStrengthMixin from '@/mixins/PasswordStrengthMixin';
import axios from 'axios';
import {mainStore} from '@/store/__STORE_main';
import {authStore} from '@/store/__STORE_auth';
export default {
  components: {
    PasswordStrength,
  },
  mixins: [
    PasswordStrengthMixin,
  ],
  props: {
    code: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      password: '',
      password_confirmation: '',
      errors: [],
      validated: false,
      expired: false,
      verificationPerformed: false,
    };
  },
  created() {
    mainStore.setLoading();
    axios({url: '/validate-forgot-password/' + this.code, method: 'POST'})
        .then((resp) => {
          this.verificationPerformed = true;
          mainStore.clearStatus();
        })
        .catch((err) => {
          const statusCode = err.response.status;
          if (statusCode === 404) {
            this.expired = true;
          }
          this.verificationPerformed = true;
          mainStore.clearStatus();
        });
  },
  methods: {
    changePassword() {
      if (this.password && !this.sufficientPassword) {
        this.errors = [];
        this.showPasswordStrengthError = true;
        return;
      }

      this.showPasswordStrengthError = false;

      const data = {
        password: this.password,
        password_confirmation: this.password_confirmation,
        code: this.code,
      };
      authStore.changePassword(data)
          .then(() => {
            this.$toast.success('Your password is changed successfully.');
            this.$router.push('/login');
          })
          .catch((err) => {
            this.$toast.error(err.response.data.message);
            if (err.response.data.errors) {
              this.errors = err.response.data.errors;
            } else {
              this.errors = [];
            }
          });
    },
  },
};
</script>
